<template>
  <el-main class="rh-table">
    <h1>Répertoire des médecins</h1>
    <app-table
      tablenom="medecins"
      :cols="colonnes"
      :items="medecins"
      :loadstatus="medecinsLoadStatus"
      :deletestatus="medecinDeleteStatus"
      :formroutes="medFormRoutes"
      :actions="medActions"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      medFormRoutes: {
        edit: { route: "donnees_perso", params: { idmed: "id" } },
        create: { route: "medecincreate" },
      },
    };
  },
  computed: {
    medecinsLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.medl.medecinsLoadStatus;
    },
    medecinDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.medl.medecinDeleteStatus;
    },
    medecins() {
      return this.$store.state.medl.medecins;
    },
    colonnes() {
      return this.$store.state.medl.colonnes;
    },
    medActions() {
      return this.$store.state.medl.actions;
    },
  },
};
</script>
